import React from "react"
import { Router } from "@reach/router"
import { login, isAuthenticated, logout, getProfile } from "../../utils/auth"
import Home from "./Home"
import Detail from "./Detail"
import "./app.css"

const Account = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }
  const user = getProfile()

  return (
    <Router>
      <Detail path="/app/detail/:detailId" />
      <Home path="/app" />
    </Router>
  )
}

export default Account
